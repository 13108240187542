import React from 'react';
import Helmet from 'react-helmet';
import { PageProps } from 'gatsby';

const NotFound: React.FC<PageProps> = () => (
  <>
    <Helmet>
      <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
    </Helmet>
    <main>
      <p>Sorry, page not found!</p>
    </main>
  </>
);

export default NotFound;
